export const menuItems = [
  {
    "id": 1,
    "name": "Fried Yam – Chicken or Fish",
    "category": "Menu",
    "img": "https://firebasestorage.googleapis.com/v0/b/browns-kitchen-6e202.appspot.com/o/BROWNS-06.jpg?alt=media&token=a7128983-f352-4582-a56d-088d10a225ad",
    "price": 60
  },
  {
    "id": 2,
    "name": "Jollof – Chicken or Fish (Only Sat 7 Sunday)",
    "category": "Menu",
    "img": "https://firebasestorage.googleapis.com/v0/b/browns-kitchen-6e202.appspot.com/o/BROWNS-02.jpg?alt=media&token=3298fbec-b839-47f3-9d6e-b24dac13863c",
    "price": 60
  },
  {
    "id": 3,
    "name": "Plain Rice Chicken or Fish",
    "category": "Menu",
    "img": "https://firebasestorage.googleapis.com/v0/b/browns-kitchen-6e202.appspot.com/o/BROWNS-03.jpg?alt=media&token=b0569b9a-9bca-4a06-bc5c-e189a2c76335",
    "price": 60
  },
  {
    "id": 4,
    "name": "Fried Rice Chicken",
    "category": "Menu",
    "img": "https://firebasestorage.googleapis.com/v0/b/browns-kitchen-6e202.appspot.com/o/Gemini_Generated_Image_rwiatgrwiatgrwia.jpg?alt=media&token=219c7c13-7ddb-4779-946a-cc2d45735110",
    "price": 60
  },
  {
    "id": 5,
    "name": "Banku & Grilled Tilapia",
    "category": "Menu",
    "img": "https://firebasestorage.googleapis.com/v0/b/browns-kitchen-6e202.appspot.com/o/086f9295e4fb8360c93a01f7f94706b2.jpg?alt=media&token=92e7511d-9b48-4089-adb5-07db52a02e00",
    "price": 85
  },
  {
    "id": 6,
    "name": "Banku & Fried Fish",
    "category": "Menu",
    "img": "https://firebasestorage.googleapis.com/v0/b/browns-kitchen-6e202.appspot.com/o/05da2cfc7d00347780737dccccb1509d.jpg?alt=media&token=b0c815ee-9567-4d74-8014-c536c624b02a",
    "price": 60
  },
  {
    "id": 7,
    "name": "Banku with Ademe Soup – Fish, Wele & crab and Salted beef",
    "category": "Menu",
    "img": "https://firebasestorage.googleapis.com/v0/b/browns-kitchen-6e202.appspot.com/o/BROWNS-01.jpg?alt=media&token=11ab6440-f55d-4b2e-a0ac-fce0e8fd4778",
    "price": 60
  },
  {
    "id": 8,
    "name": "Ewople with Octopus Stew",
    "category": "Menu",
    "img": "https://firebasestorage.googleapis.com/v0/b/browns-kitchen-6e202.appspot.com/o/Gemini_Generated_Image_t7t2bt7t2bt7t2bt.jpg?alt=media&token=f7fe11a3-9e83-4250-b124-ff488a817be1",
    "price": 65
  },
  {
    "id": 9,
    "name": "Ewokple -Abobi Tardi",
    "category": "Menu",
    "img": "https://firebasestorage.googleapis.com/v0/b/browns-kitchen-6e202.appspot.com/o/BROWNS-08.jpg?alt=media&token=ed8d0fc2-4d0b-4b97-bc2c-1117fb57080c",
    "price": 60
  },
  {
    "id": 10,
    "name": "Ewokple & Ademe Soup – Fish, Crab, Wele & Salted beef",
    "category": "Menu",
    "img": "https://firebasestorage.googleapis.com/v0/b/browns-kitchen-6e202.appspot.com/o/WhatsApp%20Image%202024-06-10%20at%2009.09.29_108d4058.jpg?alt=media&token=569de37b-5355-4d59-b987-2b5132389ad5",
    "price": 60
  },
  {
    "id": 11,
    "name": "Fufu with Groundnut Soup – Goat meat, Fresh Fish & Intestine",
    "category": "Menu",
    "img": "https://firebasestorage.googleapis.com/v0/b/browns-kitchen-6e202.appspot.com/o/BROWNS-04.jpg?alt=media&token=49eb4a3a-c9c8-4d12-841d-4e245411a134",
    "price": 60
  },
  {
    "id": 12,
    "name": "Fufu with Light Soup – 2 Pcs Goat meat",
    "category": "Menu",
    "img": "https://firebasestorage.googleapis.com/v0/b/browns-kitchen-6e202.appspot.com/o/89598063.jpg?alt=media&token=f728562e-0c12-400f-8d79-cbe2f8f1afb6",
    "price": 60
  },
  {
    "id": 13,
    "name": "Fufu with Light Soup – 2 Pcs Fresh Fish",
    "category": "Menu",
    "img": "https://firebasestorage.googleapis.com/v0/b/browns-kitchen-6e202.appspot.com/o/BROWNS-07.jpg?alt=media&token=424883fe-5015-40f7-b902-47cc6df4a59b",
    "price": 60
  },
  {
    "id": 14,
    "name": "Fufu with Light Soup – 1 Pcs Dried Fish",
    "category": "Menu",
    "img": "https://firebasestorage.googleapis.com/v0/b/browns-kitchen-6e202.appspot.com/o/brownsss.jpg?alt=media&token=817b856f-d6b2-4e6d-9997-193412758ba8",
    "price": 50
  },
  {
    "id": 15,
    "name": "Fufu with Palm nut Soup – caw meat, Tuna & Intestine",
    "category": "Menu",
    "img": "https://firebasestorage.googleapis.com/v0/b/browns-kitchen-6e202.appspot.com/o/FjTPQFOXEAAMl3m.jpg?alt=media&token=0a4dffa3-3cc0-468b-a730-bf98d95ee387",
    "price": 60
  },
  {
    "id": 16,
    "name": "Konkonte with Groundnut Soup – Cow leg, Tuna & Intestine",
    "category": "Only Weekends",
    "img": "https://firebasestorage.googleapis.com/v0/b/browns-kitchen-6e202.appspot.com/o/BROWNS-04.jpg?alt=media&token=49eb4a3a-c9c8-4d12-841d-4e245411a134",
    "price": 55
  },
  {
    "id": 17,
    "name": "Banku",
    "category": "Extra Upon request",
    "img": "https://firebasestorage.googleapis.com/v0/b/browns-kitchen-6e202.appspot.com/o/OIP%20(9).jpeg?alt=media&token=278c957e-3a36-472c-8675-1197154cf181",
    "price": 4
  },
  {
    "id": 18,
    "name": "Fufu",
    "category": "Extra Upon request",
    "img": "https://firebasestorage.googleapis.com/v0/b/browns-kitchen-6e202.appspot.com/o/Kelewele%2Band%2Bfufu.jpg?alt=media&token=b37bf8f3-6e8f-4e97-9d51-e3dec7aa5ef0",
    "price": 12
  },
  {
    "id": 19,
    "name": "Konkonte",
    "category": "Extra Upon request",
    "img": "https://firebasestorage.googleapis.com/v0/b/browns-kitchen-6e202.appspot.com/o/maxresdefault.jpg?alt=media&token=fecebd3a-d8d7-40fc-85d7-a15c12a1ab93",
    "price": 4
  },
  {
    "id": 20,
    "name": "Goat Meat",
    "category": "Extra Upon request",
    "img": "https://firebasestorage.googleapis.com/v0/b/browns-kitchen-6e202.appspot.com/o/OIP%20(8).jpeg?alt=media&token=8c06aa86-bfe1-4c3f-8934-530352b95983",
    "price": 25
  },
  {
    "id": 21,
    "name": "Cow Meat",
    "category": "Extra Upon request",
    "img": "https://firebasestorage.googleapis.com/v0/b/browns-kitchen-6e202.appspot.com/o/Gemini_Generated_Image_fgkp42fgkp42fgkp.jpg?alt=media&token=4e2eba05-51a4-491f-8103-83a87010918e",
    "price": 15
  },
  {
    "id": 22,
    "name": "Cow Leg",
    "category": "Extra Upon request",
    "img": "https://firebasestorage.googleapis.com/v0/b/browns-kitchen-6e202.appspot.com/o/WhatsApp%20Image%202024-06-10%20at%2009.09.26_bf55e734.jpg?alt=media&token=8f18f333-cd89-4ea7-afd5-27a866e6dc51",
    "price": 15
  },
  {
    "id": 23,
    "name": "Intestine",
    "category": "Extra Upon request",
    "img": "https://firebasestorage.googleapis.com/v0/b/browns-kitchen-6e202.appspot.com/o/Stir-fried-pork-intestine-with-turmeric-specialty-food-of-Quang-Ngai.jpg?alt=media&token=458dcb92-1088-4510-98a9-daf28653336a",
    "price": 15
  },
  {
    "id": 24,
    "name": "Octopus Kebbab (2 Sticks)",
    "category": "Extra Upon request",
    "img": "https://firebasestorage.googleapis.com/v0/b/browns-kitchen-6e202.appspot.com/o/chickentikka.jpg?alt=media&token=7941eca8-201c-4f15-bad5-d6257de4860b",
    "price": 35
  },
  {
    "id": 25,
    "name": "Tuna",
    "category": "Extra Upon request",
    "img": "https://firebasestorage.googleapis.com/v0/b/browns-kitchen-6e202.appspot.com/o/100_8489.jpg?alt=media&token=072bc523-47d2-4fd9-90b7-88256cf7e516",
    "price": 12
  },
  {
    "id": 26,
    "name": "Salted Beef",
    "category": "Extra Upon request",
    "img": "https://firebasestorage.googleapis.com/v0/b/browns-kitchen-6e202.appspot.com/o/WhatsApp%20Image%202024-06-10%20at%2009.09.26_678896a3.jpg?alt=media&token=f9462a57-06b2-446e-abe5-5945b7c9fb43",
    "price": 15
  },
  {
    "id": 27,
    "name": "Fresh Fish",
    "category": "Extra Upon request",
    "img": "https://firebasestorage.googleapis.com/v0/b/browns-kitchen-6e202.appspot.com/o/Gemini_Generated_Image_xnynw3xnynw3xnyn.jpg?alt=media&token=aedc1e74-300a-4c18-abed-7dc1d40db2a8",
    "price": 18
  },
  {
    "id": 28,
    "name": "Fried Fish",
    "category": "Extra Upon request",
    "img": "https://firebasestorage.googleapis.com/v0/b/browns-kitchen-6e202.appspot.com/o/Gemini_Generated_Image_rl1ayfrl1ayfrl1a.jpg?alt=media&token=f07e5ca1-bea0-4449-8527-f284bd939551",
    "price": 18
  },
  {
    "id": 29,
    "name": "Wele",
    "category": "Extra Upon request",
    "img": "https://firebasestorage.googleapis.com/v0/b/browns-kitchen-6e202.appspot.com/o/R%20(3).jpeg?alt=media&token=d60f5f31-070e-4240-bfb1-a061ffadfaf8",
    "price": 8
  },
  {
    "id": 30,
    "name": "Dried Fish",
    "category": "Extra Upon request",
    "img": "https://firebasestorage.googleapis.com/v0/b/browns-kitchen-6e202.appspot.com/o/Gemini_Generated_Image_r6yr4yr6yr4yr6yr.jpg?alt=media&token=3e0fb9db-7a87-42ab-898d-4dd18a8b1c5d",
    "price": 35
  },
  {
    "id": 31,
    "name": "Herings (Amani)",
    "category": "Extra Upon request",
    "img": "https://firebasestorage.googleapis.com/v0/b/browns-kitchen-6e202.appspot.com/o/Smoked-Herrings.jpeg?alt=media&token=79b14420-0373-4093-9533-d75ce7448b4a",
    "price": 13
  },
  {
    "id": 32,
    "name": "Pork Kebbab Available from 4pm to 8pm – Monday to Sunday",
    "category": "Extra Upon request",
    "img": "https://firebasestorage.googleapis.com/v0/b/browns-kitchen-6e202.appspot.com/o/Gemini_Generated_Image_4wtikh4wtikh4wti.jpg?alt=media&token=9de970a7-3ea9-4ebb-bd7a-9910438ac7d7",
    "price": 10
  }
]